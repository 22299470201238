<template>
  <div class="device-index">
    <div class="container-header">
      <div class="top-actions-group">
        <div class="action-left">
          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            size="medium"
            @click="onAdd"
            >新增</el-button
          >
        </div>
        <div class="action-right"></div>
      </div>
    </div>

    <el-table
      class="list-table"
      ref="multipleTable"
      :data="listData"
      tooltip-effect="dark"
      style="width: 100%"
      :stripe="true"
      :border="false"
      default-expand-all
      row-key="Id"
      :tree-props="{ children: 'children' }"
      v-loading="tableLoading"
    >
      <el-table-column label="名称" prop="Title"></el-table-column>
      <el-table-column label="菜单标识" prop="Name" width="120"></el-table-column>
      <el-table-column label="默认图标" width="80" align="center">
        <template slot-scope="scope">
          <el-avatar
            v-if="scope.row.Icon"
            shape="square"
            :size="32"
            :src="scope.row.Icon"
          ></el-avatar>
        </template>
      </el-table-column>
      <el-table-column label="激活图标" width="80" align="center">
        <template slot-scope="scope">
          <el-avatar
            v-if="scope.row.IconActive"
            shape="square"
            :size="32"
            :src="scope.row.IconActive"
          ></el-avatar>
        </template>
      </el-table-column>
      <el-table-column label="地址" prop="UrlAddress"></el-table-column>
      <el-table-column label="状态" prop="EnabledMark" width="70" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.EnabledMark == 0" effect="dark" size="small"
            >启用</el-tag
          >
          <el-tag
            v-if="scope.row.EnabledMark == 1"
            type="danger"
            effect="dark"
            size="small"
            >禁用</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="排序" prop="SortCode" width="60" align="center"></el-table-column>
      <el-table-column label="创建时间" prop="CreateTime" width="100">
        <template slot-scope="scope">
          {{ scope.row.CreateTime | datetime }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160">
        <template slot-scope="scope">
          <el-button
            type="success"
            icon="el-icon-edit"
            size="mini"
            @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-button
            type="warning"
            icon="el-icon-error"
            size="mini"
            style="margin-left: 10px"
            @click="onListDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="formtype == 'add' ? '添加菜单' : '修改菜单'"
      :visible.sync="dialogFormVisible"
      width="750px"
      custom-class="geology-dialog"
      :modal-append-to-body="true"
      :destroy-on-close="true"
      :before-close="onFormClose"
    >
      <el-form
        ref="form"
        class="geology-form"
        :rules="formRules"
        :model="form"
        label-width="120px"
        style="padding-top: 20px"
      >
        <el-row>
          <el-col :span="10" :offset="1">
            <el-form-item label="父级菜单" prop="ParentId">
              <el-select v-model="form.ParentId" placeholder="请选择">
                <el-option label="顶级菜单" :value="0"> </el-option>
                <el-option
                  v-for="item in listData"
                  :key="item.Id"
                  :label="item.Title"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10" :offset="1">
            <el-form-item label="菜单名称" prop="Title">
              <el-input v-model="form.Title" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="菜单标识" prop="Name">
              <el-input v-model="form.Name" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10" :offset="1">
            <el-form-item label="默认图标" prop="Icon">
              <single-file
                ref="menuIconUpload"
                text="选择图标"
                :auto-upload="true"
                :show-file-list="true"
                :file-list="formIcon"
                @success="menuIconUploadSuccess"
                @remove="menuIconUploadRemove"
              ></single-file>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="激活图标" prop="IconActive">
              <single-file
                ref="menuIconActiveUpload"
                text="选择图标"
                :auto-upload="true"
                :show-file-list="true"
                :file-list="formIconActive"
                @success="menuIconActiveUploadSuccess"
                @remove="menuIconActiveUploadRemove"
              ></single-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="21" :offset="1">
            <el-form-item label="URL地址" prop="UrlAddress">
              <el-input v-model="form.UrlAddress" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21" :offset="1">
            <el-form-item label="功能选项" prop="Auth">
              <div class="form-menuauth-item" v-for="(item,index) in formAuth" :key="index">
                <el-input v-model="item.value" placeholder="标识，例：add" style="width:30%;" size="small"></el-input>
                <el-input v-model="item.label" placeholder="说明，例：新增" style="width:50%;margin-left:-1px;" size="small"></el-input>
                <el-button style="margin-left:5px;" type="primary" size="small" @click="onEditAuthRemove(index)">移除</el-button>
              </div>
              <el-button type="primary" size="mini" @click="formAuth.push({label:'',value:''})">新增功能选项</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="排序" prop="SortCode">
              <el-input v-model="form.SortCode" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="是否启用" prop="EnabledMark">
              <el-switch
                v-model="form.EnabledMark"
                :active-value="0"
                :inactive-value="1"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="21" :offset="1">
            <el-form-item label="描述" prop="Description">
              <el-input v-model="form.Description" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="23" :offset="1">
            <el-form-item>
              <el-button type="primary" size="medium" @click="onSubmit"
                >立即保存</el-button
              >
              <el-button
                type="primary"
                plain
                size="medium"
                style="margin-left: 10px"
                @click="onFormClose"
                >取消</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<style lang="scss" scoped>
.form-menuauth-item{
  .el-input__inner{
    border-radius: 0;
    margin-right: 20px;
  }
}
</style>
<script>
import {
  getSysmenuList,
  delSysmenu,
  addSysmenu,
  editSysmenu,
} from "@/api/system";
import { SingleFile } from "@/components/upload";
export default {
  data() {
    return {
      listData: [],
      tableLoading: false,
      dialogFormVisible: false,

      formtype: "add",
      parentMenu: [],
      formIcon: [],
      formIconActive: [],
      form: {
        ParentId: 0,
        Title: "",
        Name: "",
        Icon: "",
        IconActive: "",
        UrlAddress: "",
        ModuleButtonHtml: "",
        IsChecked: true,
        SelectAuth: "",
        EnabledMark: 0,
        Auth: "",
        Id: 0,
        SortCode: 0,
        CreateTime: "",
      },
      formAuth:[],
      formRules: {
        ParentId: [
          { required: true, trigger: "blur", message: "请选择上级菜单" },
        ],
        Title: [{ required: true, trigger: "blur", message: "请输入菜单名称" }],
        Name: [{ required: true, trigger: "blur", message: "请输入菜单标识" }],
      },
    };
  },
  components: {
    SingleFile,
  },
  methods: {
    menuIconUploadSuccess(file) {
      this.form.Icon = file.path;
    },
    menuIconUploadRemove(file, fileList) {
      this.form.Icon = "";
    },
    menuIconActiveUploadSuccess(file) {
      this.form.IconActive = file.path;
    },
    menuIconActiveUploadRemove(file, fileList) {
      this.form.IconActive = "";
    },
    onSearch() {
      this.page.pageindex = 1;
      this.getListData();
    },
    onPageChange(pageindex) {
      this.page.pageindex = pageindex;
      this.getListData();
    },
    getListData() {
      this.tableLoading = true;
      getSysmenuList().then((resdata) => {
        this.tableLoading = false;
        if (resdata.code == 0) {
          this.listData = resdata.data;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
    onListDel(item) {
      this.$confirm("确定要删除选择的数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delSysmenu(item.Id)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("删除成功!");
                this.getListData();
              } else {
                this.$message.error("删除失败!" + res.msg);
              }
            })
            .catch((errmsg) => {
              this.$message.error(errmsg);
            });
        })
        .catch(() => {});
    },
    onAdd() {
      this.form = {
        ParentId: "",
        Title: "",
        Name: "",
        Icon: "",
        IconActive: "",
        UrlAddress: "",
        ModuleButtonHtml: "",
        IsChecked: true,
        SelectAuth: "",
        EnabledMark: 0,
        Auth: "",
        Id: 0,
        SortCode: 0,
        CreateTime: "",
      };
      this.formAuth = [];
      this.formtype = "add";
      this.dialogFormVisible = true;
    },
    onEdit(item) {
      this.form = item;
      this.formtype = "edit";
      this.formIcon = this.form.Icon
        ? [
            {
              url: this.form.Icon,
            },
          ]
        : [];
      this.formIconActive = this.form.IconActive
        ? [
            {
              url: this.form.IconActive,
            },
          ]
        : [];
      try {
        this.formAuth = this.form.Auth ? JSON.parse(this.form.Auth) : [];
      } catch (error) {
        this.formAuth = [];
      }
      this.dialogFormVisible = true;
    },
    onEditAuthRemove(index){
      this.formAuth.splice(index,1);
    },
    onFormClose() {
      this.getListData();
      this.formIcon = [];
      this.formIconActive = [];
      this.dialogFormVisible = false;
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          var formdata = JSON.parse(JSON.stringify(this.form));
          if(this.formAuth && this.formAuth.length>0){
            formdata.Auth = JSON.stringify(this.formAuth);
          }else{
            formdata.Auth = '';
          }
          if (this.formtype != "edit") {
            addSysmenu(formdata)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  this.onFormClose();
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          } else {
            editSysmenu(formdata)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  this.onFormClose();
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
  created() {
    this.getListData();
  },
};
</script>